import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { SyncLoader, Reload } from '../../Icons';
import { CreateDraft } from '../styles';
import {
  draftFormSyndication,
  setDraftViewPending,
  clearDraftViewToast,
  setDraftViewTargetBrand,
} from '../../../actions/draftView';
import { trackEvent } from '../../../helpers/googleAnalytics';
import useUpdateContentDetails from '../hooks/useUpdateContentDetails';
import useSetTargetBrand from '../hooks/useSetTargetBrand';
import useCleanup from '../hooks/useCleanup';
import useSetTranslationToastMessage from '../hooks/useSetTranslationToastMessage';
import useSetSyndicationToastMessage from '../hooks/useSetSyndicationToastMessage';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';
import useApi from '../../../hooks/useApi';
import { sortBrandMarkets } from '../utils';
import { DraftFormSyndicationErrType, MarketBrandsData, DraftViewReducertype } from '../../../types';
import { trackCustomSnowplowActions, ActionType } from '../../../analytics/snowplow';

type SlatDataType = {
  id: string;
  type: string;
  sourceUri: string;
  language: string;
  canSyndicate: boolean;
  marketBrandCode: string;
  isGlobalDraft: boolean;
  syndicationStatus: string;
  hed: string;
  description: string;
  dek: string;
  body: string;
  outro?: string;
};

type UserType = {
  name: string;
};

type DraftFormDataType = {
  targetCopilotUri: string;
};

type TargetBrandsType = {
  label: string;
  value: string;
};

const DraftFormCreateDraft = React.forwardRef(
  (
    {
      slatData,
      user,
      syndicationStatus,
    }: {
      slatData: SlatDataType;
      user: UserType;
      syndicationStatus: string;
    },
    ref: React.Ref<HTMLButtonElement> | null
  ): JSX.Element => {
    const {
      state: { draftView, globalDraft },
      dispatch,
    } = useGlobalStateValue();
    const { globalDraftsTargetMarketBrands } = globalDraft;
    const { callApi } = useApi(`post`, `/api/v1/syndicate`);
    const { id, type, sourceUri, language: sourceLang, marketBrandCode: sourceBrand, canSyndicate } = slatData;
    const {
      targetBrand,
      l10n,
      targetLang,
      marketBrands: { data },
    }: DraftViewReducertype = draftView;
    let targetBrands: MarketBrandsData[] | undefined =
      window.location.pathname === '/globalDrafts' ? globalDraftsTargetMarketBrands : data;
    let sortedTargetBrands: undefined | TargetBrandsType[] = [];
    if (Array.isArray(targetBrands)) {
      targetBrands = sortBrandMarkets(targetBrands);
      sortedTargetBrands =
        targetBrands && targetBrands.map(({ name, code }): TargetBrandsType => ({ label: name, value: code }));
    } else {
      sortedTargetBrands = [];
    }
    useCleanup();
    useUpdateContentDetails(slatData);
    useSetSyndicationToastMessage(sortedTargetBrands, targetBrand);
    useSetTranslationToastMessage();
    const setTargetBrand = (targetBrand: string) => {
      if (!targetBrand) return;
      trackEvent('Modal - Syndicate', 'Target brand change', { label: targetBrand });
      localStorage.setItem('targetBrand', targetBrand);
      dispatch && dispatch(setDraftViewTargetBrand(targetBrand));
    };
    useSetTargetBrand({ sourceBrand, setTargetBrand });
    const onSyndication = async (refreshAction: boolean) => {
      if (syndicationStatus === 'pending') return;
      dispatch && dispatch(setDraftViewPending());
      dispatch && dispatch(clearDraftViewToast());
      trackEvent('Modal - Syndicate', 'Create Draft button pressed');
      const newTargetLang = targetLang ? targetLang : sourceLang;
      const effectiveLanguage = newTargetLang.toLowerCase();
      const slatLanguage = slatData.language.toLowerCase();
      const translate = slatLanguage !== effectiveLanguage;
      const data = {
        sourceUri,
        sourceBrand,
        targetBrand,
        syndicationAuthor: user.name,
        type,
        translate,
        targetLang: newTargetLang,
        l10n: l10n[newTargetLang],
        refreshAction,
      };

      trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
        component: 'draft_form_createDraft',
        label: 'create_draft',
        type: 'click',
      });

      if (refreshAction) {
        trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
          component: 'draft_form_createDraft',
          label: 'refresh',
          type: 'click',
        });
      }

      const config = {
        data,
        url: '/api/v1/syndicate',
        validateStatus: (status: number) => {
          return [200, 409].includes(status);
        },
      };
      const response = await callApi(config);
      const { data: draftFormData, status, error } = response;

      if ((draftFormData as DraftFormDataType)?.targetCopilotUri && status && [200, 409].includes(status)) {
        const targetId = (draftFormData as DraftFormDataType)?.targetCopilotUri.split('/').pop();
        trackCustomSnowplowActions(ActionType.EncoreContent, {
          target_brand: targetBrand,
          source_brand: sourceBrand,
          source_id: id,
          target_id: targetId,
          content_type: type,
          source_lang: sourceLang,
          target_lang: newTargetLang,
        });
        trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
          component: 'draft_form_success',
          label: 'create_draft_success',
          type: 'click',
        });
      }

      dispatch &&
        dispatch(
          draftFormSyndication({
            data: draftFormData as DraftFormDataType,
            status,
            error: error as DraftFormSyndicationErrType,
            targetBrand,
          })
        );
    };

    const renderButton = () => {
      let buttonContent;
      const isRestrictedVPATargetLang = targetBrand === 'vpa' && ['en-GB', 'en-US'].includes(targetLang);
      if (syndicationStatus === 'pending') {
        buttonContent = <SyncLoader data-testid="syncloader" />;
      } else if (syndicationStatus === 'timeout') {
        buttonContent = (
          <>
            <Reload data-testid="reload" />
            <span>
              <FormattedMessage
                id="create.modal.reloadDraftText"
                description="Reload Draft label"
                defaultMessage="Refresh"
              />
            </span>
          </>
        );
      } else {
        buttonContent = (
          <FormattedMessage
            id="create.modal.createDraftText"
            description="Create Draft label"
            defaultMessage="Create Draft"
          />
        );
      }
      return (
        <button
          ref={ref}
          id="createDraft"
          disabled={!canSyndicate || !targetBrand || isRestrictedVPATargetLang}
          type="submit"
          className={classNames({ pending: syndicationStatus === 'pending' }, 'button--primary', 'syndicate-button')}
          onClick={() => onSyndication(syndicationStatus === 'timeout')}
        >
          {buttonContent}
        </button>
      );
    };

    return <CreateDraft>{renderButton()}</CreateDraft>;
  }
);

export default DraftFormCreateDraft;
