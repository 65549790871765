import { Dispatch } from 'react';
import { DRAFT_VIEW_CONSTANTS } from '../constants/reducer-constants';
import {
  ErrorMessageType,
  DraftViewErrorMessageType,
  DraftViewToastType,
  ToastType,
  ReducerActionType,
  DraftViewTranslationResponseType,
  DraftViewTranslationPayloadType,
  ToggleDraftViewToastType,
  MarketBrandsActionType,
  MarketBrandsData,
  GlobalStateInterface,
  Action,
  TranslateDraftFormType,
  TranslateDraftFormPayloadType,
  DraftFormSyndicationType,
  ContentItem,
  rightsToastStatusType,
  imageRightsCountType,
} from '../types';
const {
  SET_DRAFTVIEW_TOAST,
  SET_DRAFTVIEW_ERROR_MESSAGE,
  SET_DRAFTVIEW_TARGET_BRAND,
  SET_DRAFTVIEW_PENDING,
  SET_DRAFTVIEW_ERROR,
  SET_DRAFTVIEW_SUCCESS,
  SET_DRAFTVIEW_DUPLICATE,
  SET_DRAFTVIEW_INPROGRESS,
  SET_DRAFTVIEW_TIMEOUT,
  RESET_DRAFTVIEW_REDUCER,
  SET_DRAFT_FORM_TARGET_LANGUAGE,
  SET_DRAFTVIEW_TRANSLATION_RESPONSE,
  SET_DRAFT_FORM_SOURCE_LANGUAGE,
  SET_DRAFTVIEW_TRANSLATION_STATUS,
  TOGGLE_DRAFTVIEW_TOAST,
  CLEAR_DRAFTVIEW_TOAST,
  SET_MARKET_BRANDS,
  SET_CONTENT_DATA,
  SET_IMAGES_WITHRIGHTS_STATUS,
  SET_RIGHTS_TOAST_STATUS,
  SET_TOTAL_IMAGE_RIGHTS_COUNT,
  MISSING_DESTINATION_CONTENT_TYPE,
} = DRAFT_VIEW_CONSTANTS;

type PayloadType = {
  [key: string]: DraftViewTranslationPayloadType;
};

export const setDraftViewErrorMessage = (payload: ErrorMessageType): DraftViewErrorMessageType => {
  return {
    type: SET_DRAFTVIEW_ERROR_MESSAGE,
    payload,
  };
};

export const setDraftViewToast = (payload: ToastType): DraftViewToastType => {
  return {
    type: SET_DRAFTVIEW_TOAST,
    payload,
  };
};

export const setDraftViewError = (): { type: string } => {
  return {
    type: SET_DRAFTVIEW_ERROR,
  };
};

export const setDraftViewDuplicate = (targetCopilotUri: string): ReducerActionType => {
  return {
    type: SET_DRAFTVIEW_DUPLICATE,
    payload: targetCopilotUri,
  };
};

export const setDraftViewSuccess = (targetCopilotUri: string): ReducerActionType => {
  return {
    type: SET_DRAFTVIEW_SUCCESS,
    payload: targetCopilotUri,
  };
};

export const setDraftViewPending = (): { type: string } => {
  return {
    type: SET_DRAFTVIEW_PENDING,
  };
};

export const setDraftViewInProgress = (): { type: string } => {
  return {
    type: SET_DRAFTVIEW_INPROGRESS,
  };
};

export const setDraftViewTimeout = (): { type: string } => {
  return {
    type: SET_DRAFTVIEW_TIMEOUT,
  };
};

export const setMissingDestinationContentType = (): { type: string } => {
  return {
    type: MISSING_DESTINATION_CONTENT_TYPE,
  };
};

export const setDraftViewTargetBrand = (targetBrand: string): ReducerActionType => {
  return {
    type: SET_DRAFTVIEW_TARGET_BRAND,
    payload: targetBrand,
  };
};

export const resetDraftViewReducer = (): { type: string } => {
  return {
    type: RESET_DRAFTVIEW_REDUCER,
  };
};

export const setDraftViewTranslationResponse = (payload: PayloadType): DraftViewTranslationResponseType => {
  return {
    type: SET_DRAFTVIEW_TRANSLATION_RESPONSE,
    payload,
  };
};

export const setDraftFormTargetLanguage = (payload: string): ReducerActionType => {
  return {
    type: SET_DRAFT_FORM_TARGET_LANGUAGE,
    payload,
  };
};

export const setDraftFormSourceLanguage = (payload: string): ReducerActionType => {
  return {
    type: SET_DRAFT_FORM_SOURCE_LANGUAGE,
    payload,
  };
};

export const setTranslationStatus = (payload: string): ReducerActionType => {
  return {
    type: SET_DRAFTVIEW_TRANSLATION_STATUS,
    payload,
  };
};

export const setMarketBrands = (payload: MarketBrandsData[]): MarketBrandsActionType => ({
  type: SET_MARKET_BRANDS,
  payload,
});

export const toggleDraftViewToast = (flag: boolean): ToggleDraftViewToastType => {
  return {
    type: TOGGLE_DRAFTVIEW_TOAST,
    payload: flag,
  };
};

export const clearDraftViewToast = (): { type: string } => {
  return {
    type: CLEAR_DRAFTVIEW_TOAST,
  };
};

export const translateDraftForm = ({ data = [], error, targetLang }: TranslateDraftFormType) => {
  return async (dispatch: Dispatch<Action>, state: GlobalStateInterface): Promise<void> => {
    if (!error) {
      const {
        draftView: { l10n },
      } = state;
      data.forEach(({ hed, dek, body, pros, cons, outro }) => {
        const payload = {} as TranslateDraftFormPayloadType;
        if (!l10n[targetLang]) {
          payload[targetLang] = { hed, dek, body, pros, cons, outro };
          dispatch(setDraftViewTranslationResponse(payload));
        }
      });
      dispatch(setTranslationStatus('success'));
    } else {
      dispatch(clearDraftViewToast());
      dispatch(setTranslationStatus('error'));
      dispatch(setDraftFormTargetLanguage(''));
      dispatch(toggleDraftViewToast(true));
    }
  };
};

export const draftFormSyndication = ({ data, status }: DraftFormSyndicationType) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    if (status === 200) {
      dispatch(setDraftViewSuccess(data?.targetCopilotUri));
    } else if (status === 202) {
      dispatch(setDraftViewInProgress());
    } else if (status === 409) {
      dispatch(setDraftViewDuplicate(data?.targetCopilotUri));
    } else if (status === 504) {
      dispatch(setDraftViewTimeout());
    } else if (status === 404) {
      dispatch(setMissingDestinationContentType());
    } else {
      dispatch(setDraftViewError());
    }
    dispatch(toggleDraftViewToast(true));
  };
};

export const setContentData = (
  payload: ContentItem | Record<string, never>
): { type: string; payload: ContentItem | Record<string, never> } => {
  return {
    type: SET_CONTENT_DATA,
    payload,
  };
};

export const setImagesWithRightsStatus = (payload: string): { type: string; payload: string } => {
  return {
    type: SET_IMAGES_WITHRIGHTS_STATUS,
    payload,
  };
};

export const setRightsToastStatus = (
  payload: rightsToastStatusType
): { type: string; payload: rightsToastStatusType } => {
  return {
    type: SET_RIGHTS_TOAST_STATUS,
    payload,
  };
};

export const setImageRightsCount = (payload: imageRightsCountType): { type: string; payload: imageRightsCountType } => {
  return {
    type: SET_TOTAL_IMAGE_RIGHTS_COUNT,
    payload,
  };
};
