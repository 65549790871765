import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFeatureToggle } from '@flopflip/react-broadcast';

import SyndicationToast from '../SyndicationToast';
import DraftFormDestinaltion from './DraftForm.Destination';
import DraftFormImageRightsNotice from './DraftForm.ImageRightsNotice';
import DraftFormCreateDraft from './DraftForm.CreateDraft';
import DraftFormFormattedMessage from './DraftForm.FormattedMessage';
import CopyDraft from '../../CopyDraft/CopyDraft';
import { BottomActions } from '../styles';

import useTextToCopy from '../hooks/useTextToCopy';

import { SyndicationDraftForm, ToastWrapper } from '../styles';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';
import { trackEvent } from '../../../helpers/googleAnalytics';
import { sortBrandMarkets } from '../utils';
import { toggleDraftViewToast, setDraftViewTargetBrand } from '../../../actions/draftView';
import { DraftViewReducertype, MarketBrandsData } from '../../../types';
import { RIGHTS_PHASE_ONE, SYNDICATION_COUNT } from '../../../helpers/flags';

type SlatDataType = {
  id: string;
  type: string;
  sourceUri: string;
  language: string;
  canSyndicate: boolean;
  marketBrandCode: string;
  isGlobalDraft: boolean;
  syndicationStatus: string;
  dek: string;
  hed: string;
  description: string;
  body: string;
  outro?: string;
};

type UserType = {
  name: string;
};

type TargetBrandsType = {
  label: string;
  value: string;
};

const DraftForm = ({ slatData, user }: { slatData: SlatDataType; user: UserType }): JSX.Element => {
  const { type, canSyndicate } = slatData;
  const isTargetDemoBrandEnabled = useFeatureToggle('targetDemoBrand');
  const syndicationCountEnabled = useFeatureToggle(SYNDICATION_COUNT);
  const rightsPhaseOneEnabled = useFeatureToggle(RIGHTS_PHASE_ONE);
  const {
    state: { draftView, globalDraft },
    dispatch,
  } = useGlobalStateValue();
  const {
    syndicationStatus,
    targetCopilotUri,
    toast,
    showToast,
    marketBrands: { data },
  }: DraftViewReducertype = draftView;
  const textToCopy = useTextToCopy();
  const draftButtonRef = React.createRef<HTMLButtonElement>();
  const { globalDraftsTargetMarketBrands } = globalDraft;
  const toastHandler = () => {
    draftButtonRef?.current?.click();
  };
  const setTargetBrand = (targetBrand: string) => {
    if (!targetBrand) return;
    trackEvent('Modal - Syndicate', 'Target brand change', { label: targetBrand });
    localStorage.setItem('targetBrand', targetBrand);
    dispatch && dispatch(setDraftViewTargetBrand(targetBrand));
  };

  const defaultMessage = toast.type === 'timeout' ? 'Refresh to check status' : 'View Draft';

  let targetBrands: MarketBrandsData[] | undefined =
    window.location.pathname === '/globalDrafts' ? globalDraftsTargetMarketBrands : data;
  let sortedTargetBrands: undefined | TargetBrandsType[] = [];
  if (Array.isArray(targetBrands)) {
    targetBrands = sortBrandMarkets(targetBrands);
    sortedTargetBrands =
      targetBrands && targetBrands.map(({ name, code }): TargetBrandsType => ({ label: name, value: code }));
  } else {
    sortedTargetBrands = [];
  }

  const getTargetBrands = (isTargetDemoFeatureEnabled: boolean, slatData: SlatDataType) => {
    const DEMO_BRANDS = [
      { label: 'Demo', value: 'dmo' },
      { label: 'Demo EU', value: 'demoeu' },
      { label: 'Demo AP', value: 'demoap' },
    ];
    const { marketBrandCode } = slatData;
    let allBrands: TargetBrandsType[] | undefined;
    if (!isTargetDemoFeatureEnabled) allBrands = sortedTargetBrands;
    else if (sortedTargetBrands?.length) allBrands = [...DEMO_BRANDS, ...sortedTargetBrands];
    return allBrands?.filter(({ value }) => value !== marketBrandCode);
  };

  React.useEffect(() => {
    const { targetBrand: isTargetBrand } = draftView;
    if (!isTargetBrand) {
      const savedTargetBrand = localStorage.getItem('targetBrand');
      if (savedTargetBrand) {
        setTargetBrand(savedTargetBrand);
      } else {
        const options = getTargetBrands(isTargetDemoBrandEnabled, slatData);
        !!options && setTargetBrand(options[0]?.value);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [draftView]);

  return (
    <SyndicationDraftForm rightsPhaseOneEnabled={rightsPhaseOneEnabled}>
      <BottomActions syndicationCountEnabled={syndicationCountEnabled}>
        <DraftFormFormattedMessage slatData={slatData} />
        <DraftFormDestinaltion slatData={slatData} />
        <DraftFormImageRightsNotice />
        <DraftFormCreateDraft
          ref={draftButtonRef}
          slatData={slatData}
          user={user}
          syndicationStatus={syndicationStatus}
        />
        {type !== 'gallery' && <CopyDraft copyText={textToCopy} canSyndicate={canSyndicate} />}
      </BottomActions>
      {showToast && syndicationStatus !== 'pending' && (
        <ToastWrapper data-testid="toast">
          <SyndicationToast
            title={toast.message}
            close={() => {
              dispatch && dispatch(toggleDraftViewToast(false));
            }}
            showToast={showToast}
            toastData={toast}
            targetCopilotUri={targetCopilotUri}
            action={toastHandler}
          >
            <FormattedMessage
              id="create.modal.viewDraft"
              description="View Draft link text"
              defaultMessage={defaultMessage}
            />
          </SyndicationToast>
        </ToastWrapper>
      )}
    </SyndicationDraftForm>
  );
};

export default React.memo(DraftForm);
