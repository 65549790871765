import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '../../Select/Select';
import { languages } from '../../../languagePack';
import { LanguageLabel, Translation, TranslationLabel } from '../styles';
import { trackEvent } from '../../../helpers/googleAnalytics';
import defaultLanguageResource from '../../../translations/en.json';
import {
  translateDraftForm,
  setDraftFormTargetLanguage,
  toggleDraftViewToast,
  setTranslationStatus,
} from '../../../actions/draftView';
import useApi from '../../../hooks/useApi';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';
import { DraftViewTranslationPayloadType, TranslationBody } from '../../../types';
import { useFeatureToggle } from '@flopflip/react-broadcast';
export const SYNDICATION_COUNT = 'syndicationCount';
import { stringChunk } from '../../../utils/commonMethods';
import config from '../../../config';

const { inputSizeLimit } = config;

type SlatDataType = {
  id: string;
  type: string;
  sourceUri: string;
  language: string;
  canSyndicate: boolean;
  marketBrandCode: string;
  isGlobalDraft: boolean;
  syndicationStatus: string;
  hed: string;
  description: string;
  body: string;
  pros?: string;
  cons?: string;
  outro?: string;
};

const DraftFormFormattedMessage = ({ slatData }: { slatData: SlatDataType }): JSX.Element => {
  const {
    state: { draftView },
    dispatch,
  } = useGlobalStateValue();
  const { callApi } = useApi(`post`, `/api/v1/syndicate`);
  const {
    hed,
    description,
    body,
    pros = '',
    cons = '',
    language: sourceLang,
    marketBrandCode: sourceBrand,
    outro,
  } = slatData;

  const { targetLang, l10n } = draftView;
  const syndicationCountEnabled = useFeatureToggle(SYNDICATION_COUNT);
  const translationHandler = async ({ value }: { value: string }) => {
    const newTargetLang = value || sourceLang;
    trackEvent('Modal - Syndicate', 'Encore Translation', {
      label: sourceBrand,
      dimension1: sourceLang,
      dimension2: newTargetLang,
    });

    dispatch && dispatch(toggleDraftViewToast(false));
    dispatch && dispatch(setDraftFormTargetLanguage(value));

    if (!value || !!l10n[newTargetLang]) return;
    dispatch && dispatch(setTranslationStatus('pending'));

    const sendBodyChunk = (data: TranslationBody[]) => {
      const config = {
        data,
        url: '/api/v1/translate',
        validateStatus: (status: number) => {
          return [200].includes(status);
        },
      };
      return callApi(config);
    };

    let retry = 0;
    const translateOperation = async () => {
      let promises = [];
      promises.push(sendBodyChunk([{ hed, dek: description, sourceLang, targetLang: value, pros, cons }]));

      const chunks = stringChunk(body, retry === 0 ? inputSizeLimit : 5000);
      promises = [
        ...promises,
        ...(chunks?.map((chk) => sendBodyChunk([{ body: chk, sourceLang, targetLang: value }])) ?? []),
      ];
      if (outro) {
        const outroChunks = stringChunk(outro, retry === 0 ? inputSizeLimit : 5000);
        promises = [
          ...promises,
          ...(outroChunks?.map((ochk) => sendBodyChunk([{ outro: ochk, sourceLang, targetLang: value }])) ?? []),
        ];
      }

      const responses = await Promise.all(promises);
      const errors = responses?.filter(({ error }) => error);
      if (errors && errors?.length) {
        retry += 1;
        if (retry > 1) {
          dispatch &&
            dispatch(
              translateDraftForm({
                data: [],
                error: errors[0].error as Error,
                targetLang: value,
              })
            );
        } else {
          await translateOperation();
        }
      } else {
        const responseData = responses[0].data as TranslationBody[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const translatedBody = responses.slice(1).reduce((acc, curr: any) => {
          return acc + curr?.data[0].body;
        }, '');

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const translatedOutro = responses.slice(2).reduce((acc, curr: any) => {
          return curr?.data[0].outro ? acc + curr?.data[0].outro : acc;
        }, '');

        const data = {
          body: translatedBody,
          hed: responseData[0].hed,
          dek: responseData[0].dek,
          outro: translatedOutro,
        } as TranslationBody;

        if (responseData[0].pros) {
          data.pros = responseData[0].pros;
        }

        if (responseData[0].cons) {
          data.cons = responseData[0].cons;
        }

        dispatch &&
          dispatch(
            translateDraftForm({
              data: [data] as DraftViewTranslationPayloadType[],
              error: '',
              targetLang: value,
            })
          );
      }
    };

    try {
      await translateOperation();
    } catch (error) {
      retry += 1;
      if (retry > 1) {
        dispatch &&
          dispatch(
            translateDraftForm({
              data: [],
              error: error as Error,
              targetLang: value,
            })
          );
      } else {
        await translateOperation();
      }
    }
  };

  return (
    <>
      {!syndicationCountEnabled && (
        <>
          <FormattedMessage
            id="create.modal.originalLanguage"
            description="Original language label"
            defaultMessage="Original Language"
          />
          <LanguageLabel data-testid="OriginalLanguage">
            <FormattedMessage
              id={sourceLang}
              description="Language of the Article"
              defaultMessage={(defaultLanguageResource as { [key: string]: string })[sourceLang]}
            />
          </LanguageLabel>
        </>
      )}
      <Translation syndicationCountEnabled={syndicationCountEnabled}>
        <TranslationLabel>
          <FormattedMessage
            id="create.modal.translation"
            description="Translation label"
            defaultMessage="Translation"
          />
        </TranslationLabel>
        <Select
          type="translation"
          data-testid="translation"
          value={targetLang}
          options={languages}
          onChange={translationHandler}
        />
      </Translation>
    </>
  );
};

export default DraftFormFormattedMessage;
